<template>
	<v-app>
		<v-main>
			<v-row class="fill-height" align="start" justify="center">
				<!-- Left Side Image Section -->
				<v-col cols="12" md="6" class="d-none d-md-block pa-0">
					<v-img
						src="@/assets/img/common/login.jpg"
						alt="Forgot password page illustration"
					>
					</v-img>
				</v-col>

				<!-- Right Side Form Section -->
				<v-col cols="12" md="6" class="mt-8 px-6 px-sm-16">
					<div class="mx-0 mx-sm-8 mx-md-n6 mx-lg-16">
						<!-- Header Content -->
						<header>
							<a href="https://www.unosearch.net">
								<v-img
									src="@/assets/img/logo/brand-icon.svg"
									max-height="60"
									max-width="60"
									class="mb-6"
									to="/"
									contain
								></v-img>
							</a>

							<h1>Forgot your password?</h1>
							<p class="grey--text text--darken-1">
								Don't worry, happens to the best of us.
							</p>
							<p
								class="
									grey--text
									text--darken-1
									mt-6
									text-subtitle-2
								"
							>
								Enter the email address associated with your
								account and we'll send you a link to reset your
								password.
							</p>
						</header>

						<!-- Actual Form -->
						<v-form class="mt-8" @submit.prevent="forgotPassword">
							<!-- Alert box to display Success Message -->
							<v-alert
								:value="showSuccessAlert"
								type="success"
								dismissible
								prominent
								transition="fade-transition"
								@click="showSuccessAlert = false"
								class="mb-10"
							>
								Your password reset email has been sent. Please
								check your inbox to reset your password.
							</v-alert>

							<!-- Alert box to display Server Errors -->
							<v-alert
								:value="showServerErrorAlert"
								type="error"
								dismissible
								prominent
								transition="fade-transition"
								@click="showServerErrorAlert = false"
								class="mb-10"
							>
								{{ serverErrorMessage }}
							</v-alert>

							<!-- Email Field -->
							<v-text-field
								v-model="forgotPasswordForm.email"
								placeholder="Email address"
								required
								outlined
								type="email"
								:error-messages="emailErrors"
								@blur="$v.forgotPasswordForm.email.$touch()"
							></v-text-field>

							<!-- Submit Button -->
							<v-btn
								type="submit"
								@click.prevent="forgotPassword()"
								:loading="isLoading"
								x-large
								block
								color="primary"
								class="white--text mt-2"
							>
								Request Password Reset Link
							</v-btn>

							<!-- Link to Login Page -->
							<p
								class="
									text-center
									mt-8
									grey--text
									text--darken-2
								"
							>
								<router-link to="/login" class="link-text">
									<v-icon color="primary">
										mdi-chevron-left
									</v-icon>
									Back to Login
								</router-link>
							</p>
						</v-form>
					</div>
				</v-col>
			</v-row>
		</v-main>
	</v-app>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'

export default {
	mixins: [validationMixin],
	validations: {
		forgotPasswordForm: {
			email: {
				required,
				email,
				maxLength: maxLength(255),
			},
		},
	},
	data() {
		return {
			forgotPasswordForm: {
				email: '',
			},
			showSuccessAlert: false,
			showServerErrorAlert: false,
			serverErrorMessage: '',
			isLoading: false,
		}
	},
	computed: {
		emailErrors() {
			const errors = []
			if (!this.$v.forgotPasswordForm.email.$dirty) return errors
			!this.$v.forgotPasswordForm.email.required &&
				errors.push('Please enter your Email address.')
			!this.$v.forgotPasswordForm.email.email &&
				errors.push('Email must be valid.')
			!this.$v.forgotPasswordForm.email.maxLength &&
				errors.push('Email must be atmost 100 characters long.')
			return errors
		},
	},
	methods: {
		forgotPassword() {
			this.$v.forgotPasswordForm.$touch()
			if (
				!this.$v.forgotPasswordForm.$invalid ||
				!this.$v.forgotPasswordForm.$error
			) {
				this.isLoading = true
				this.axios
					.post('/auth/forgot-password', this.forgotPasswordForm)
					.then((res) => {
						if (res.data.success) {
							this.isLoading = false
							this.showServerErrorAlert = false
							this.showSuccessAlert = true
							console.log(res)
						}
					})
					.catch((err) => {
						this.isLoading = false
						this.showSuccessAlert = false
						const { data } = err.response
						this.serverErrorMessage = !data.success
							? data.message
							: err
						this.showServerErrorAlert = true
						throw new Error(err)
					})
			}
		},
	},
}
</script>

<style scoped>
.v-image {
	height: 100vh !important;
}
.v-input {
	font-size: 1.1rem;
}
.link-text {
	text-decoration: none;
	color: --var(primary);
}
</style>
